
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import { UserForgotPassword } from "@/store/user/types";

const VForgotPassword = defineComponent({
  name: "VForgotPassword",
  components: { CFormInputText },
  setup() {
    useHead({ title: "Recupere sua senha logo abaixo | obmed" });

    const store = useStore();
    const router = useRouter();

    const form = reactive<UserForgotPassword>({ ds_email: "" });
    const loading = reactive({ submit: false });

    async function handleForgotPassword() {
      loading.submit = true;
      const response = await store.dispatch("forgotPassword", { form });
      loading.submit = false;

      if (response?.status === 200) router.replace({ name: "root" });
    }

    return { form, loading, handleForgotPassword };
  },
});

export default VForgotPassword;
